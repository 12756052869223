<template>
  <div class="login flex">
    <div class="topRight common">
      <img src="../../assets/img/animalTop.png" alt="">
    </div>
    <div class="bottomLeft common">
      <img src="../../assets/img/animalBottom.png" alt="">
    </div>
    <div class="loginContent">
      <div class="content">
        <div class="logo"><img src="../../assets/img/ysLogo.png" alt=""></div>
        <div class="start flex">
          <div class="left">
            <!--          <img src="../../assets/img/ysLogoSys.png" alt="" class="logoT">-->
            <h1>{{ $store.state.setting.systemName }}</h1>
            <h4>{{ $store.state.setting.enName }}</h4>
            <el-radio-group v-model="userState" class="radioGroup">
              <el-radio :label="1">会员登录</el-radio>
              <!--            <el-radio :label="2">访客登录</el-radio>-->
              <el-radio :label="3">公司注册</el-radio>
            </el-radio-group>
            <router-link class="startButton" :to="userState==1?'/login':(userState==3?'/regist':'')">
              开始&nbsp;|&nbsp;START
            </router-link>
            <div class="littleFeet">
              <img src="../../assets/img/feetS.png" alt="" class="feetImgS ">
              <img src="../../assets/img/feetL.png" alt="" class="feetImgL">
            </div>
          </div>
          <div class="right">
            <img src="../../assets/img/animalSys.png" alt="" width="100%">
            <img src="../../assets/img/mouse.png" alt="" width="90%" class="mouse">
          </div>
        </div>
      </div>
      <div class="footer" v-html=" $store.state.setting.copyright"></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "welcome",
  data() {
    return {
      userState: 1
    }
  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/login";

.start {
  padding-top: 10%;

  h3 {
    margin-top: 10px;
  }
}
</style>
